var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"id":"achTableResult","items":_vm.items,"fields":_vm.fields,"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme},scopedSlots:_vm._u([{key:"№",fn:function(ref){
var index = ref.index;
return [_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateFormate(item.created))+" ")])]}},{key:"actions-header",fn:function(){return [_c('div',{directives:[{name:"theme-header-actions",rawName:"v-theme-header-actions"}]})]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"fixed-column-end"},[_c('div',{staticClass:"zq--table-actions-wrap table--action-delete",on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('i',{staticClass:"fa fa-times"})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }