<template>
  <CCard class="zq-card-table">
    <CCardBody>
      <CDataTable
          id="achTableResult"
          class="zq--table"
          :items="items"
          :fields="fields"
          :responsive="true"
          :border="isDefaultTheme"
          :hover="isDefaultTheme"
          :striped="isDefaultTheme"
      >
        <!--     INDEX   -->
        <template #№="{index}">
          <td>
            {{ index + 1 }}
          </td>
        </template>
        <!--   ID     -->
        <template #id="{item}">
          <td>
            {{ item.id }}
          </td>
        </template>
        <!--   NAME    -->
        <template #name="{item}">
          <td>
            {{ item.name }}
          </td>
        </template>
        <!--   DESCRIPTION    -->
        <template #description="{item}">
          <td v-html="item.description"></td>
        </template>
        <!--   created    -->
        <template #created="{item}">
          <td>
            {{ dateFormate(item.created) }}
          </td>
        </template>
        <!--    ACTIONS    -->
        <template #actions-header>
          <div v-theme-header-actions></div>
        </template>

        <template #actions="{item}">
          <td class="fixed-column-end">
            <div class="zq--table-actions-wrap table--action-delete" @click="deleteItem(item.id)">
              <i class="fa fa-times"></i>
            </div>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import {mapGetters} from "vuex";
import {dateFormate} from '@/utils';


export default {
  props: {
    items: Array,
    fields: Array
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  methods: {
    dateFormate(val) {
      return dateFormate(val);
    },
    deleteItem(item) {
      this.$emit('delete', item);
    }
  }
}
</script>

<style>
.table--action-delete{
  cursor: pointer;
}
</style>